//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------- 
// Dependencies
import React, { useEffect, useState } from "react";
import { db } from "./../../firebase/config";
import CandidateCard from "../../common/candidateCard/CandidateCard";
import { Candidate } from "../../types/candidate";
import { Typography, Carousel } from "antd";
import { useSelector } from "react-redux";
import { Job } from "../../types/position";
import { Content } from "antd/lib/layout/layout";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { getCandidate } from "../../firebase/endpoints/candidateEndPoint";
import { PreCandidateInterface, PreCandidateRecommendationInterface } from "../../models/preCandidate.interface";
import { getPreCandidateDocument } from "../../firebase/controllers/PreCandidate.services.firebase";
import PreCandidateCard from "../../common/PreCandidateCard/PreCandidateCard";
import LoadingCard from "./LoadingCard";
import SourcingLoading from "./SourcingLoading";

// Interface
interface Props {
  position: Job;
}

// Component
const { Text } = Typography;

const CandidateList: React.FC<Props> = ({ position }) => {
  // State
  const [dataListUser, setDataListUser] = useState<Array<Candidate>>([]);
  const [dataListPreCandidate, setDataListPreCandidate] = useState<Array<PreCandidateInterface>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { dictionary } = useSelector((state: any) => state.ui);

  const user = useSelector((state: any) => state.auth.user);
  let carouselRef: any; // Referencia al componente Carousel
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const next = () => {
    if ((currentIndex + 1) < totalCount) {
      carouselRef.next();
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prev = () => {
    if ((currentIndex + 1) > 1) {
      setCurrentIndex(currentIndex - 1);
      carouselRef.prev();
    }
  };

  useEffect(() => {
    const fetchCandidates = async () => {
      setLoading(true);
      const recommendationCandidatesRef = collection(db, `hirings/${user?.idHiring}/positions/${position?.idPosition}/recommendationCandidates`);
      const q = query(recommendationCandidatesRef, where("like", "==", ""));

      const unsubscribeCandidates = onSnapshot(q, async (recommendationCandidateCollection) => {
        const docs: Array<Candidate> = [];
        const recommendationCandidates = recommendationCandidateCollection.docs.map(doc => doc.data());
        for (const recomCandidate of recommendationCandidates) {
          const candidateDoc = recomCandidate as Candidate;
          const candidate: any = await getCandidate(candidateDoc.id);
          const newCandidate: any = {
            ...candidate,
            name: candidate.name,
            lastName: candidate.lastName
          };
          docs.push(newCandidate);
        }
        setDataListUser(docs);
      });

      const recommPreCandidateRef = collection(db, 'hirings', user.idHiring, 'positions', position.idPosition, 'recommendationPreCandidates');
      const recommendationPreCandidatesQuery = query(recommPreCandidateRef, where('hiringLike', '==', null));

      const unsubscribePreRecommendations = onSnapshot(recommendationPreCandidatesQuery, async (snapshot: any) => {
        const recommendationPreCandidatesCollect = snapshot.docs.map((doc: any) => doc.data() as PreCandidateRecommendationInterface);
        const newList: Array<PreCandidateInterface> = [];
        for (const recomPreCan of recommendationPreCandidatesCollect) {
          const { success, data } = await getPreCandidateDocument(recomPreCan.id);
          if (success && data) {
            newList.push(data);
          }
        }
        console.log(newList)
        setDataListPreCandidate(newList);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });

      return () => {
        unsubscribeCandidates();
        unsubscribePreRecommendations();
      };
    };

    if (user?.idHiring && position?.idPosition) {
      fetchCandidates();
    }
  }, [user?.idHiring, position?.idPosition]);

  useEffect(() => {
    const combinedLength = dataListUser.length + dataListPreCandidate.length;
    setTotalCount(combinedLength);
    if ((currentIndex + 1) > combinedLength) {
      setCurrentIndex(combinedLength);
    }
  }, [dataListUser, dataListPreCandidate, currentIndex]);


  useEffect(() => {
    setLoading(true);

    return () => {
      setDataListPreCandidate([]);
      setDataListUser([]);
    }
  }, []);

  return (
    <Content style={{ height: "100%", width: "100%" }}>
      {loading ? (
        <LoadingCard />
      ) : (
        dataListUser.length > 0 ?
          <>
            <Carousel
              dots={false}
              ref={c => (carouselRef = c)}
              style={{ height: "100%", width: "100%" }}
            >
              {dataListUser.map((item) => (
                <CandidateCard
                  candidate={item}
                  user={user}
                  position={position}
                  key={item.id}
                  home
                  prev={prev}
                  next={next}
                />
              ))}
              {dataListPreCandidate.map((item) => {
                return (
                  <PreCandidateCard
                    key={item.idPreCandidate}
                    next={next}
                    position={position}
                    preCandidate={item}
                    prev={prev}
                    user={user}
                  />
                )
              })}
            </Carousel>
            {
              totalCount > 0 &&
              <div style={{ width: 60, height: 35, position: "absolute", top: "3%", right: "3%", display: "flex", justifyContent: "center", alignItems: "center", border: '1px solid #00000040', borderRadius: 16, backgroundColor: '#6052A3', boxShadow: '0px 0px 10px #00000016' }}>
                <Text style={{ textAlign: "center", fontSize: 23, color: 'white' }} >{currentIndex + 1}/{totalCount}</Text>
              </div>
            }

          </> : dataListPreCandidate.length > 0 ? <>
            <Carousel
              dots={false}
              ref={c => (carouselRef = c)}
              style={{ height: "100%", width: "100%" }}
            >
              {dataListPreCandidate.map((item) => {
                console.log('hola')
                return (
                  <PreCandidateCard
                    key={item.idPreCandidate}
                    next={next}
                    position={position}
                    preCandidate={item}
                    prev={prev}
                    user={user}
                  />
                )
              })}
            </Carousel>
            {
              totalCount > 0 &&
              <div style={{ width: 60, height: 35, position: "absolute", top: "3%", right: "3%", display: "flex", justifyContent: "center", alignItems: "center", border: '1px solid #00000040', borderRadius: 16, backgroundColor: '#6052A3', boxShadow: '0px 0px 10px #00000016' }}>
                <Text style={{ textAlign: "center", fontSize: 23, color: 'white' }} >{currentIndex + 1}/{totalCount}</Text>
              </div>
            }

          </> :
            <SourcingLoading dictionary={dictionary} />
      )}
    </Content>
  );
};

export default CandidateList;
