import React, { useState } from 'react';
import { Button, Input, List, Typography, Row, Col } from 'antd';
import { DeleteOutlined, EditOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const { Text } = Typography;
const { TextArea } = Input;

interface ListItem {
    index: number;
    value: string;
}

interface EditableListProps {
    title: string;
    items: ListItem[];
    setItems: (value: ListItem[]) => void;
}

const MAX_ITEMS = 5;

const EditableList: React.FC<EditableListProps> = ({ title, items, setItems }) => {
    const [newItem, setNewItem] = useState<string>('');
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [editingValue, setEditingValue] = useState<string>('');
    const [movingIndices, setMovingIndices] = useState<{ current: number | null, target: number | null }>({ current: null, target: null });

    const handleAdd = () => {
        if (newItem.trim()) {
            const newItemObject = { index: items.length + 1, value: newItem };
            setItems([...items, newItemObject]);
            setNewItem('');
        }
    };

    const handleDelete = (index: number) => {
        const updatedList = items.filter(item => item.index !== index);
        setItems(updatedList);
    };

    const handleEdit = (index: number, value: string) => {
        setEditingIndex(index);
        setEditingValue(value);
        setNewItem('');
    };

    const handleSaveEdit = () => {
        if (editingIndex !== null) {
            const updatedList = items.map(item =>
                item.index === editingIndex ? { ...item, value: editingValue } : item
            );
            setItems(updatedList);
            setEditingIndex(null);
            setEditingValue('');
        }
    };

    const handleCancelEdit = () => {
        setEditingIndex(null);
        setEditingValue('');
    };

    const moveItem = (currentIndex: number, direction: 'up' | 'down') => {
        const targetIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
        if (targetIndex < 0 || targetIndex >= items.length) return;

        const reorderedItems = [...items];
        [reorderedItems[currentIndex], reorderedItems[targetIndex]] = [reorderedItems[targetIndex], reorderedItems[currentIndex]];

        setItems(reorderedItems.map((item, idx) => ({ ...item, index: idx })));
        setMovingIndices({ current: currentIndex, target: targetIndex });

        setTimeout(() => setMovingIndices({ current: null, target: null }), 400); // Resetea después de la animación
    };

    return (
        <div style={{ width: '100%' }}>
            <Row style={{ marginBottom: '28px', alignItems: 'flex-start' }}>
                <Col flex="200px" style={{ paddingRight: '10px' }}>
                    <Typography.Title level={4} style={{ marginBottom: 0 }}>
                        {title}
                    </Typography.Title>
                </Col>
                <Col flex="auto" style={{ paddingRight: '20px' }}>
                    <TextArea
                        value={newItem}
                        onChange={(e) => setNewItem(e.target.value)}
                        placeholder={`Add new ${title.toLowerCase()}`}
                        rows={4}
                        maxLength={350}
                        showCount
                        style={{ borderRadius: '8px', width: '100%' }}
                        disabled={editingIndex !== null || items.length >= MAX_ITEMS}
                    />
                </Col>
                <Col flex="100px" style={{ textAlign: 'right' }}>
                    <Button
                        type="primary"
                        onClick={handleAdd}
                        className="button-table-add"
                        disabled={editingIndex !== null || items.length >= MAX_ITEMS}
                        style={{
                            borderBottomRightRadius: '20px',
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '0px',
                            borderBottomLeftRadius: '0px',
                            width: '110px',
                            backgroundColor: '#22c0c1',
                            color: 'white',
                            boxShadow: 'inset 7px 7px 15px #00000030',
                        }}
                    >
                        Add
                    </Button>
                </Col>
            </Row>

            <List
                bordered
                dataSource={items}
                style={{ backgroundColor: 'white' }}
                renderItem={(item, index) => (
                    <List.Item
                        style={{
                            transform:
                                movingIndices.current === index
                                    ? `translateY(${movingIndices.target! > index ? '20px' : '-20px'})`
                                    : movingIndices.target === index
                                        ? `translateY(${movingIndices.current! > index ? '20px' : '-20px'})`
                                        : 'translateY(0)',
                            transition: 'transform 0.4s cubic-bezier(0.77, 0, 0.175, 1), background-color 0.4s ease',
                            backgroundColor: movingIndices.current === index || movingIndices.target === index ? '#f0f5ff' : 'transparent',
                        }}
                        actions={
                            editingIndex === null
                                ? [
                                    <Button
                                        icon={<EditOutlined style={{ color: 'white' }} />}
                                        onClick={() => handleEdit(item.index, item.value)}
                                        type="default"
                                        style={{
                                            borderRadius: '50%',
                                            backgroundColor: '#1890ff',
                                            color: 'white',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    />,
                                    <Button
                                        icon={<DeleteOutlined style={{ color: 'white' }} />}
                                        onClick={() => handleDelete(item.index)}
                                        type="default"
                                        danger
                                        style={{
                                            borderRadius: '50%',
                                            backgroundColor: '#ff4d4f',
                                            color: 'white',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    />,
                                    <Button
                                        icon={<ArrowUpOutlined style={{ color: index === 0 ? '#d9d9d9' : 'white' }} />}
                                        onClick={() => moveItem(index, 'up')}
                                        type="default"
                                        disabled={index === 0}
                                        style={{
                                            borderRadius: '50%',
                                            backgroundColor: index === 0 ? '#f5f5f5' : '#8c8c8c',
                                            color: index === 0 ? '#d9d9d9' : 'white',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    />,
                                    <Button
                                        icon={<ArrowDownOutlined style={{ color: index === items.length - 1 ? '#d9d9d9' : 'white' }} />}
                                        onClick={() => moveItem(index, 'down')}
                                        type="default"
                                        disabled={index === items.length - 1}
                                        style={{
                                            borderRadius: '50%',
                                            backgroundColor: index === items.length - 1 ? '#f5f5f5' : '#8c8c8c',
                                            color: index === items.length - 1 ? '#d9d9d9' : 'white',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    />
                                ]
                                : []
                        }
                    >
                        {editingIndex === item.index ? (
                            <Row gutter={8} style={{ width: '100%', alignItems: 'flex-start', marginBottom: '12px' }}>
                                <Col flex="auto">
                                    <TextArea
                                        value={editingValue}
                                        onChange={(e) => setEditingValue(e.target.value)}
                                        rows={4}
                                        maxLength={350}
                                        showCount
                                        style={{ borderRadius: '8px', width: '100%' }}
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        onClick={handleCancelEdit}
                                        type="default"
                                        style={{
                                            borderBottomRightRadius: '20px',
                                            borderTopLeftRadius: '20px',
                                            borderTopRightRadius: '0px',
                                            borderBottomLeftRadius: '0px',
                                            width: '110px',
                                            backgroundColor: 'red',
                                            color: 'white',
                                            boxShadow: 'inset 7px 7px 15px #00000030',
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type="primary"
                                        onClick={handleSaveEdit}
                                        className="button-table-add"
                                        style={{
                                            borderBottomRightRadius: '20px',
                                            borderTopLeftRadius: '20px',
                                            borderTopRightRadius: '0px',
                                            borderBottomLeftRadius: '0px',
                                            width: '110px',
                                            backgroundColor: '#22c0c1',
                                            color: 'white',
                                            boxShadow: 'inset 7px 7px 15px #00000030',
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        ) : (
                            <Text>{item.value}</Text>
                        )}
                    </List.Item>
                )}
            />
        </div>
    );
};

export default EditableList;
