import React, { useState } from 'react';
import RegisterLoading from './RegisterLoading';
import RegisterSuccess from './RegisterSucess';
import RegisterError from './RegisterError';
import VerificationStep from './VerificationStep';
import RegisterForm from './registerForm/RegisterForm';

// Component
const RegisterCard: React.FC = () => {

    // States
    const [step, setStep] = useState<'Initial' | 'VerificationEmail' | 'completeRegistration' | 'Error' | 'Loading'>('Initial');
    const [userInformation, setUserInformation] = useState<any | null>(null);
    return (
        <>
            {
                step === 'Loading' ? (
                    <RegisterLoading />
                ) : step === 'Initial' ? (
                    <RegisterForm setStep={setStep} setUserInformation={setUserInformation} />
                ) : step === 'completeRegistration' ? (
                    <RegisterSuccess />
                ) : step === 'Error' ? (
                    <RegisterError setStep={setStep} />
                ) : step === 'VerificationEmail' && (
                    <VerificationStep user={userInformation} setStep={setStep} />
                )
            }
        </>
    )
}

export default RegisterCard;
