//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Col, Row, Typography } from "antd";
import React, { Dispatch, useEffect, useState } from "react";
import iconPlus from "../../image/ico-add-job-3x.png";
import iconWork from "../../image/ico-work-3x.png";
import iconDash from "../../image/ico-dashboard-3x.png";
/* import iconPeers from "../../image/ico-peer-3x.png"; */
import iconPersonSearch from "../../image/ico-sourcing-3x.png";
import { db } from "../../firebase/config";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import "./Menu.css";
import { Content } from "antd/lib/layout/layout";
import CreateJobModal from "../modals/createJobModal/CreateJobModal";
import SourcingButton from "./SourcingButton";
import JobsButton from "./JobsButton";
import { clearFilterJob, clearMessageList, clearPositionList, clearPositionSelect, clearUserChatSelected } from "../../store/chat";
import { getPositionSideBarSelected } from "../../store/ui";
import { clearUserSelected, startGetFirstCandidate } from "../../store/job";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";

const { Text } = Typography;
// Component
const Menu: React.FC = () => {

  // States
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
  const location = useLocation();

  const positionJobSelected = useSelector((state: any) => state.ui.position);
  const { user } = useSelector((state: any) => state.auth);
  const { dictionary } = useSelector((state: any) => state.ui);

  const [positionList, setPositionList] = useState<any>([]);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [positionSelected, setPositionSelected] = useState(0);

  // Handlers
  const showModal = () => {
    setVisibleModal(true);
  };

  const closeModal = () => {
    setVisibleModal(false);
  };


  useEffect(() => {
    let unsubscribe: any;
    if (user?.idHiring) {
      const recommCandidateRef = collection(db, "hirings", user.idHiring, "positions");

      const recommCandidateQuery = query(recommCandidateRef, orderBy("title", "asc"))

      unsubscribe = onSnapshot(recommCandidateQuery, (querySnapshot) => {
        const docs: any = [];
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data() });
        });

        const result = docs.filter((d: any) => d.status === 'open' || d.status === 'market');
        setPositionList(result);
      })
    }
    return () => unsubscribe && unsubscribe();
  }, [user?.idHiring])



  const startResetDataMessage = () => {
    dispatch(clearPositionList());
    dispatch(clearMessageList());
    dispatch(clearUserChatSelected());
    dispatch(clearPositionSelect());
    dispatch(clearFilterJob());
  }

  const startResetDataJobList = () => {
    dispatch(clearUserSelected());
  }

  return (
    <Content className="contenedor"
      style={{
        height: "100%",
        width: "100%",
        borderRight: "solid 1px #00000021",
        overflow: 'auto',
        padding: 2
      }}
    >
      {/* ----------------------------------  MenuITems ---------------------- */}
      <Row
        style={{
          backgroundColor: "white",
          width: "100%",
        }}
      >

        {/* ----------------------------------  DASHBOARD ---------------------- */}
        <Row
          style={
            location.pathname === "/dash"
              ? {
                backgroundColor: "#24cece",
                cursor: "pointer",
                marginTop: "10%",
                height: 40,
                alignItems: "center",
                padding: "2% 0% 2%",
                width: '100%'
              }
              : {
                cursor: "pointer",
                height: 40,
                marginTop: "10%",
                alignItems: "center",
                padding: "2% 0% 2%",
                width: '100%'
              }
          }
          onClick={() => {
            location.pathname === "/messages" && startResetDataMessage();
            location.pathname === "/jobList" && startResetDataJobList();
            navigate("/dash");
          }}
        >
          <Col style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: "center" }} xs={6} sm={6} md={6} lg={6} xxl={6}>
            <img
              src={iconDash}
              style={{ height: 25, width: 30 }}
              alt={iconWork}
            />
          </Col>

          <Col style={{ height: "100%", display: 'flex', alignItems: 'center', paddingLeft: 3 }} xs={18} sm={18} md={18} lg={18} xxl={18}>
            <Text style={{ color: "black", fontSize: 14, fontFamily: 'Muli', fontWeight: "bold" }}>
              {dictionary['sidebar-button-title-dashboard']}
            </Text>
          </Col>
        </Row>

        {/* ---------------------------------- Sourcing ---------------------- */}

        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            height: 40,
            width: '100%',
            alignItems: "center",
            padding: "2% 0% 2%",
          }}
        >
          <Col style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: "center" }} xs={6} sm={6} md={6} lg={6} xxl={6}>
            <img
              src={iconPersonSearch}
              style={{ height: 25, width: 30 }}
              alt={iconWork}
            />
          </Col>
          <Col style={{ height: "100%", display: 'flex', alignItems: 'center', paddingLeft: 3 }} xs={18} sm={18} md={18} lg={18} xxl={18}>
            <Text style={{ color: "black", fontSize: 14, fontFamily: 'Muli', fontWeight: "bold" }}>
              {dictionary['sidebar-title-sourcing']}
            </Text>
          </Col>
        </Row>

        <Row style={{ width: '100%' }}>
          {positionList.map((position: any, positionIndex: any) => (
            <SourcingButton
              key={position.idPosition}
              handleOnClickButton={() => {
                location.pathname === "/messages" && startResetDataMessage();

                location.pathname === "/jobList" && startResetDataJobList();

                if (location.pathname === "/recommendation") {
                  setPositionSelected(positionIndex);
                  dispatch(getPositionSideBarSelected(position));
                } else {
                  navigate("/recommendation");
                  setPositionSelected(positionIndex);
                  dispatch(getPositionSideBarSelected(position));
                }
              }}
              position={position}
              positionIndex={positionIndex}
              positionSelected={positionSelected}
            />

          ))}
        </Row>

        {/* ---------------------------------- Jobs ---------------------- */}


        <Row
          style={{
            padding: "2% 0% 2%",
            height: 40,
            marginTop: '3%', marginBottom: '2%',
            width: '100%',
            alignItems: "center"
          }}
        >

          <Col style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: "center" }} xs={6} sm={6} md={6} lg={6} xxl={6}>
            <img
              src={iconWork}
              style={{ height: 25, width: 30 }}
              alt={iconWork}
            />
          </Col>

          <Col style={{ height: "100%", display: 'flex', alignItems: 'center', paddingLeft: 3 }} xs={18} sm={18} md={18} lg={18} xxl={18}>
            <Text style={{ color: "black", fontSize: 14, fontFamily: 'Muli', fontWeight: "bold" }}>
              {dictionary['sidebar-title-jobs']}
            </Text>
          </Col>
        </Row>

        <Row style={{ width: '100%' }}>
          {positionList.map((position: any, positionIndex: any) => (
            <JobsButton
              key={positionIndex + 'jobButton'}
              handleOnClickButton={() => {
                location.pathname === "/messages" && startResetDataMessage();
                location.pathname === "/jobList" && startResetDataJobList();

                if (location.pathname === "/jobList") {

                  dispatch(getPositionSideBarSelected(position));

                  dispatch(startGetFirstCandidate(user, position));
                } else {
                  navigate("/jobList");
                  dispatch(getPositionSideBarSelected(position));
                  dispatch(startGetFirstCandidate(user, position));
                }
              }}
              position={position}
              positionIndex={positionIndex}
              positionJobSelected={positionJobSelected}
            />
          ))}

          <Row
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              padding: "2% 0% 2%",
              width: "100%",
            }}
            onClick={showModal}
          >
            <Col style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: "center" }} xs={6} sm={6} md={6} lg={6} xxl={6}>
              <img
                src={iconPlus}
                style={{ height: 27, width: 37 }}
                alt={iconPlus}
              />
            </Col>
            <Col style={{ height: "100%", display: 'flex', alignItems: 'center', paddingLeft: 3 }} xs={18} sm={18} md={18} lg={18} xxl={18}>
              <Text style={{ color: "black", fontSize: 14, fontFamily: 'Muli', fontWeight: "bold" }}>
                {dictionary['sidebar-button-title-add-new-job']}
              </Text>
            </Col>
          </Row>
        </Row>
      </Row>
      <CreateJobModal closeModal={closeModal} user={user} visibleModal={visibleModal} />
    </Content>
  );
};

// Export
export default Menu;
