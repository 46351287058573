//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MensajeIngles from '../../../../../lang/diccionary-en.json';
import MensajeFrance from '../../../../../lang/diccionary-fr.json';
import TableDrawSkill from '../../../../tableDrawSkill/TableDrawSkill';
import EditableList from '../../../../EditableList';

//Interface
interface Props {
  requirements: Array<any>;
  setRequirements: (value: Array<any>) => void;
  isEditing: boolean;
  skills: Array<any>;
  setSkills: (value: Array<any>) => void;
}

// Component
const ThirdStepCreationJob: React.FC<Props> = ({ requirements, setRequirements, isEditing, skills, setSkills }) => {

  //States Requirements
  const { language } = useSelector((state: any) => state.ui);
  const [messageTranslate, setMessageTranslate] = useState<any>(MensajeIngles);
  // States Skills
  useEffect(() => {
    if (language === 'English') {
      setMessageTranslate(MensajeIngles);
    } else {
      //@ts-ignore
      setMessageTranslate(MensajeFrance)
    }
  }, [language]);

  return (
    <Content style={{ minHeight: "75vh", width: "100%", overflow: "auto", padding: "3%", backgroundColor: "#f4f7fc" }}>
      <Row style={{ width: "100%", padding: "1%" }}>

        <EditableList items={requirements} setItems={setRequirements} title={`${messageTranslate["creation-modal-page-three-field-requirements"]} *`} />
      </Row>

      <Row style={{ width: "100%", display: "flex", padding: "1%" }}>
        <TableDrawSkill
          title={messageTranslate["creation-modal-page-three-field-skill"]}
          dataSource={skills}
          setDataSource={setSkills}
        />
      </Row>
    </Content>
  );
}

// Exports
export default ThirdStepCreationJob;