//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Col, Input, Row, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db, storage } from "../../../../firebase/config";
import icoSendMessage from "../../../../image/ico_sent-plane.png";
import { uid } from "uid";
import UploadButtonDocument from "../../../../common/UploadButtonDocument";
import UploadButtonImage from "../../../../common/UploadButtonImage";
import PreviewMessageFile from "./PreviewFileMessage";
import routes from "../../../../api";
import Axios from "../../../../api/api";
import { collection, doc, getDocs, query, runTransaction, Timestamp, updateDoc, where } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { Candidate } from "../../../../types/candidate";
import { getCandidate } from "../../../../firebase/endpoints/candidateEndPoint";
import { fetchSendEmail } from "../../../../services/api.services";
import { EEmail, EEmailLanguage } from "../../../../models/emails";
import { getCandidateDocument } from "../../../../firebase/services/candidatesCollect/candidate";
//interface
interface Props {
  isChatScreen: boolean;
}

const baseURL = routes.mode === "dev" ? routes.dev_route : routes.qa_route

// Component
const InputChat: React.FC<Props> = ({ isChatScreen }) => {
  // States
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const candidate = useSelector((state: any) => state.jobList.userSelected);
  const candidateChat = useSelector((state: any) => state.chat.userChatSelected);
  const { dictionary } = useSelector((state: any) => state.ui);
  const [candidateData, setCandidateData] = useState<Candidate | null>(null)
  const [loading, setLoading] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");
  const candidateId = candidateChat.id !== "" ? candidateChat.id : candidate.id;
  const [document, setDocument] = useState<{
    name: string;
    url: string;
  } | null>(null);
  const [image, setImage] = useState<{ name: string; url: string } | null>(
    null
  );
  const [statusInput, setStatusInput] = useState<"error" | "warning" | undefined>(undefined);
  const { TextArea } = Input;


  const getInformationCandidate = useCallback(async (idCandidate: string) => {
    try {
      const candidateRes: any = await getCandidate(idCandidate)
      if (!candidateRes) { return null }

      setCandidateData(candidateRes)
    } catch (error) {

    }
  },
    [],
  )

  useEffect(() => {
    getInformationCandidate(candidateId);
  }, [candidateId, getInformationCandidate])


  const updateLastMessageCandidate = async () => {
    const config = {
      headers: {
        Authorization: `${token}`
      }
    };

    // Axios request to your backend
    try {
      await Axios.post(`${baseURL}/user/notificationChat`, { id: candidateId }, config);
    } catch (error) {
      console.error("Error in Axios request:", error);
      // Handle Axios request error
    }

    // Firestore update operation
    try {
      const messageDocRef = doc(db, `candidates/${candidateId}/messages`, user?.idHiring);
      await updateDoc(messageDocRef, {
        dateLastMessage: Timestamp.now(),
      });
    } catch (error) {
      console.error("Error updating Firestore document:", error);
      // Handle Firestore update error
    }
  };

  const handleOnSeenMessage = async () => {
    try {
      // Reference to the chat documents that need to be updated
      const chatRef = collection(db, `hirings/${user?.idHiring}/messages/${candidateId}/chat`);
      const q = query(chatRef, where("chatUser", "==", "to"), where("check", "==", false));
      const chatSnap = await getDocs(q);

      // Batch update each chat document
      chatSnap.forEach(async (chat) => {
        const chatDocRef = doc(db, `hirings/${user?.idHiring}/messages/${candidateId}/chat`, chat.id);
        await updateDoc(chatDocRef, { check: true });
      });

      // Update the messages document
      const messageDocRef = doc(db, `hirings/${user?.idHiring}/messages`, candidateId);
      await updateDoc(messageDocRef, {
        messagesPending: false,
        notReaded: 0,
      });
    } catch (error) {
      console.error("Error updating messages:", error);
      // Handle the error appropriately
    }
  };

  const getDocumentData = async (idHiring: string, idCandidate: string, documentName: string, url: any): Promise<{ fromUrlDocument: string, toUrlDocument: string }> => {

    const response = await fetch(url);

    const blob = await response.blob();

    const fromUserPathStorageRef = ref(storage, `imagesChat/${idHiring}/${idCandidate}/${documentName}`);

    const toUserPathStorageRef = ref(storage, `imagesChat/${candidateId}/${user.idHiring}/${documentName}`);

    await uploadBytes(fromUserPathStorageRef, blob);
    await uploadBytes(toUserPathStorageRef, blob);
    const fromUrlDocument = await getDownloadURL(fromUserPathStorageRef);
    const toUrlDocument = await getDownloadURL(toUserPathStorageRef);

    return { fromUrlDocument, toUrlDocument }

  }

  const getImageData = async (idHiring: string, idCandidate: string, url: any): Promise<{
    fromUrlImage: string, toUrlImage: string
  }> => {

    const idImage = uid(20);
    const response = await fetch(url);

    const blob = await response.blob();

    const fromUserPathStorageRef = ref(storage, `imagesChat/${user.idHiring}/${idCandidate}/${idImage}`);

    const toUserPathStorageRef = ref(storage, `imagesChat/${idCandidate}/${user.idHiring}/${idImage}`);

    await uploadBytes(fromUserPathStorageRef, blob);
    await uploadBytes(toUserPathStorageRef, blob);
    const fromUrlImage = await getDownloadURL(fromUserPathStorageRef);
    const toUrlImage = await getDownloadURL(toUserPathStorageRef);

    return { fromUrlImage, toUrlImage }

  }

  const handleOnSendMessage = async () => {
    try {
      if (input.length === 0 && image === null && document === null) {
        return;
      };
      const candidateDocument = await getCandidateDocument(candidateId);
      if (!candidateDocument.success) { return }

      setLoading(true);

      const fromUserRef = collection(db, "hirings", user.idHiring, "messages", candidateId, "chat");
      const toUserRef = collection(db, "candidates", candidateId, "messages", user.idHiring, "chat");



      let fromUrlImage: string | null = null;
      let toUrlImage: string | null = null;

      let fromUrlDocument: string | null = null;
      let toUrlDocument: string | null = null;

      if (image !== null) {
        const imageData = await getImageData(user.idHiring, candidateId, image.url);
        fromUrlImage = imageData.fromUrlImage;
        toUrlImage = imageData.toUrlImage;
      }
      if (document !== null) {
        const documentData = await getDocumentData(user.idHiring, candidateId, document.name, document.url);
        fromUrlDocument = documentData.fromUrlDocument;
        toUrlDocument = documentData.toUrlDocument;
      };
      const idMsg = uid(20);

      const fromAdminMessageData = {
        id: idMsg,
        chatUser: "from",
        text: input.length > 0 ? input : null,
        date: Timestamp.now(),
        nameDocument: document !== null ? document.name : null,
        urlImage: image !== null ? fromUrlImage : null,
        urlDocument: document !== null ? fromUrlDocument : null,
        check: false
      };

      const toDestinationMessageData = {
        id: idMsg,
        chatUser: "to",
        text: input.length > 0 ? input : null,
        date: Timestamp.now(),
        nameDocument: document !== null ? document.name : null,
        urlImage: image !== null ? toUrlImage : null,
        urlDocument: document !== null ? toUrlDocument : null,
        check: false
      }

      await runTransaction(db, async (transaction) => {
        // Referencia al documento específico dentro de la subcolección 'chat'
        const specificDocRef = doc(fromUserRef, idMsg);

        // Establece el documento con los datos proporcionados
        transaction.set(specificDocRef, fromAdminMessageData);
      });


      await runTransaction(db, async (transaction) => {
        // Referencia al documento específico dentro de la subcolección 'chat'
        const specificDocRef = doc(toUserRef, idMsg);

        // Establece el documento con los datos proporcionados
        transaction.set(specificDocRef, toDestinationMessageData);
      });

      if (!candidateData) { return }
      updateLastMessageCandidate();
      await fetchSendEmail(candidateDocument.data.email, EEmail.newMessage, candidateDocument.data.language === "English" ? EEmailLanguage.English : EEmailLanguage.French, {}, token)
      setLoading(false);
      setDocument(null);
      setInput("");
    } catch (error) {
      setLoading(false);
      console.error("Error sending message:", error);
      // Handle the error appropriately
    }
  }


  return (
    <Row style={{ height: '100%', width: '100%' }}>
      <Row
        style={{
          height: "30%",
          width: "100%",
        }}
      >
        <Col
          style={{
            height: "100%",
            width: 'fit-content',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <UploadButtonDocument setDocument={setDocument} />
        </Col>

        <Col

          style={{
            height: "100%",
            width: 'fit-content',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <UploadButtonImage setImage={setImage} />
        </Col>
        <Col
          xs={isChatScreen ? 18 : 20}
          lg={isChatScreen ? 18 : 20}
          style={{ height: '100%' }}>
          {image ? (
            <PreviewMessageFile
              fileType="image"
              nameFile={image.name}
              setFile={setImage}
            />
          ) : (
            document && (
              <PreviewMessageFile
                fileType="image"
                nameFile={document.name}
                setFile={setDocument}
              />
            )
          )}
        </Col>
      </Row>

      <Row
        style={{
          height: "70%",
          width: "100%",
          borderRadius: 10,
          border: '1px solid #cecece',
          boxShadow: "2px 2px 5px #00000020",
          marginBottom: 50,
          padding: 5,
          backgroundColor: 'white'

        }}
      >
        <Col
          style={{
            width: isChatScreen ? "94%" : "90%",
            padding: '0.5%'
          }}
        >
          <TextArea
            onFocus={handleOnSeenMessage}
            allowClear
            disabled={loading}
            onChange={(e) => {
              e.preventDefault();
              setInput(e.target.value);
              setStatusInput(undefined);
            }}
            placeholder={dictionary['message-input-send-message-placeholder']}
            style={{ fontFamily: 'Muli' }}
            status={statusInput}
            bordered={false}
            value={input}

          />
        </Col>

        <Col
          style={{
            paddingTop: isChatScreen ? 8 : 8,
            width: isChatScreen ? "6%" : "10%",
          }}
        >
          {!loading ? (
            <Row
              style={{

                cursor: "pointer",
                display: "flex",
                flexDirection: "row", justifyContent: 'center', alignContent: 'center',
                height: isChatScreen ? "80%" : "90%",
                paddingLeft: 10,
                width: "90%",
              }}
              onClick={handleOnSendMessage}
            >
              <img
                src={icoSendMessage}
                style={{ height: 26, width: 24, margin: 0, paddingTop: 4 }}
                alt={icoSendMessage}
              />
            </Row>
          ) : (
            <div
              style={{
                height: isChatScreen ? "80%" : "90%",
                width: "90%",
                borderTopRightRadius: 12,
                borderBottomRightRadius: 12,
                paddingTop: "0.5vh",
                paddingLeft: 10,
                flexDirection: "row",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => {
                setLoading(false);
              }}
            >
              <Spin />
            </div>
          )}
        </Col>
      </Row>
    </Row>
  );
};

// Export
export default InputChat;
