import { doc, setDoc } from "firebase/firestore";
import { db } from "../config";
import { ICard } from "../../models/notificationCards";
import { CollectionsName } from "../../models/collections";

interface SuccessResponse {
    success: true;
}

interface ErrorResponse {
    success: false;
    error: string;
}

type SetCardDocumentResponse = SuccessResponse | ErrorResponse;

export const setCardDocument = async (idCandidate: string, values: ICard): Promise<SetCardDocumentResponse> => {
    try {

        const cardDocumentRef = doc(db, CollectionsName.NOTIFICATIONCCARDS, idCandidate, CollectionsName.CARDS, values.idCard)

        await setDoc(cardDocumentRef, values)

        return { success: true };
    } catch (error) {
        console.error("Error al establecer el documento de la tarjeta: ", error);

        const errorMessage = error instanceof Error ? error.message : 'Error desconocido al establecer el documento de la tarjeta';

        return { success: false, error: errorMessage };
    }
};

export const setNotificationCard = async (idCandidate: string): Promise<SetCardDocumentResponse> => {
    try {
        const notificationCardDocRef = doc(db, CollectionsName.NOTIFICATIONCCARDS, idCandidate);

        await setDoc(notificationCardDocRef, { idCandidate });

        return { success: true };
    } catch (error) {
        console.error("Error al establecer el documento de notificación de tarjeta: ", error);

        const errorMessage = error instanceof Error ? error.message : 'Error desconocido al establecer el documento de notificación de tarjeta';

        return { success: false, error: errorMessage };
    }
};