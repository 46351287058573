import { db } from "../../firebase/config";
import { loginWithEmailPassword } from "../../firebase/providers";
import { getTranslation } from "../../hooks/hooks";
import { checkingCredentials, getError, updateUser } from "./authSlice";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";

export const startSignInWithEmailAndPassword = ({ email, password, dictionary }) => {
  return async (dispatch) => {
    try {
      const loginWithEmailAndPasswordResult = await loginWithEmailPassword(email, password);

      if (loginWithEmailAndPasswordResult === true) {
        // Inicia sesion correctamente.
        dispatch(checkingCredentials(false));
      } else {
        if (loginWithEmailAndPasswordResult.code === "auth/wrong-password") {
          dispatch(getError(getTranslation("login-error-wrong-password", dictionary)));
        } else if (loginWithEmailAndPasswordResult.code === "auth/user-not-found") {
          dispatch(getError(getTranslation("login-error-wrong-password", dictionary)));
        } else if (loginWithEmailAndPasswordResult.code === "auth/too-many-requests") {
          dispatch(getError(getTranslation("login-error-too-many-requests", dictionary)));
        } else {
          dispatch(getError(getTranslation("failed-attempt-error-text-title-general", dictionary)));
        }
        dispatch(checkingCredentials(false));
      }
    } catch (error) {
      dispatch(getError(getTranslation("failed-attempt-error-text-title-general", dictionary)));
      dispatch(checkingCredentials(false));
    }
  };
};

export const startUpdateUserInformation = (user) => {
  return async (dispatch) => {
    try {
      const userDataFirebase = {
        avatarCompany: user.avatarCompany,
        avatarPersonal: user.avatarPersonal,
        company: user.company,
        communicationLanguaje: user.communicationLanguaje,
        companyInformation: user.companyInformation,
        companyWebsite: user.companyWebsite,
        email: user?.email,
        firstLogin: false,
        forceChangePassword: false,
        idHiring: user?.idHiring,
        idCompanyQR: user?.idCompanyQR,
        industry: user.industry,
        lastName: user.lastName,
        name: user.name,
        phoneNumber: user.phoneNumber,
        title: user.title,
      };
      const hiringDocRef = doc(db, "hirings", user?.idHiring);
      await updateDoc(hiringDocRef, userDataFirebase);
      dispatch(updateUser(userDataFirebase));
      const positionsCollectionRef = collection(
        db,
        `hirings/${user?.idHiring}/positions`
      );
      const positionsSnapshot = await getDocs(positionsCollectionRef);
      positionsSnapshot.docs.forEach(async (positionDoc) => {
        if (user.companyInformation) {
          const positionDocRef = doc(
            db,
            `hirings/${user?.idHiring}/positions`,
            positionDoc.id
          );
          await updateDoc(positionDocRef, {
            companyInformation: user.companyInformation,
          });
        }
      });
      // Dispatch the Redux action
    } catch (error) {
      console.error("Error in startUpdateUserInformation:", error);
    }
  };
};
