import { Timestamp } from "firebase/firestore";

export interface INotificationCard {
    idCandidate: string;
}

export interface ICard {
    title: string;
    description: string;
    idNotification: string;
    idPosition: string;
    idCard: string;
    statusView: boolean;
    dateCreatedNotification: Timestamp;
    dateViewNotification: Timestamp | null;
    cardType: ECardType;
}

export enum ECardType {
    declinedCandidate = 'declinedCandidate',
}