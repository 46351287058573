//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import React, { Dispatch, useEffect, useRef, useState } from "react";
import { Button, Input, Form, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import NotificationLogin from "../../common/modals/notificationModal/NotificationLogin";
import { API_RECAPTCHA_SITE_KEY } from "../../api/secrets";
import ReCAPTCHA from "react-google-recaptcha";
import { getTranslation, verificationTokenGoogle } from "../../hooks/hooks";
import { checkingCredentials, clearError, getError, startSignInWithEmailAndPassword } from "../../store/auth";
import { StateGeneralRedux } from "../../types/redux";
import './index.css'
// Component

// Component
const LoginForm: React.FC = () => {

  // Recaptcha Google
  const reCaptchaRef = useRef<any>();
  // Redux Store
  const dispatch: Dispatch<any> = useDispatch();
  const { dictionary } = useSelector((state: StateGeneralRedux) => state.ui);
  const { loading, error } = useSelector((state: StateGeneralRedux) => state.auth);

  //States
  const [emailInput, setEmailInput] = useState<string>("");
  const [passwordInput, setPasswordInput] = useState<string>("");
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [captchaDone, setCaptchaDone] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);

  // Handlers
  const onChange = (value: string | null) => {
    setCaptchaDone(true);
    setToken(value);
  };

  // Iniciar sesion
  const handleOnStartLogInApp = async () => {
    try {
      dispatch(checkingCredentials(true));
      // Veritificacion de token
      const verificationTokenGoogleResponse = await verificationTokenGoogle(token);
      if (!verificationTokenGoogleResponse) {
        return dispatch(getError("Recaptcha verification required"));
      }

      dispatch(
        startSignInWithEmailAndPassword({
          email: emailInput,
          password: passwordInput,
          dictionary: dictionary,
        })
      );
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (error !== null) {
      reCaptchaRef.current.reset();
      setVisibleModal(true);
    }
  }, [error]);

  return (
    <div className="container-login-card">
      <NotificationLogin visible={visibleModal} closeModal={() => { setVisibleModal(false); dispatch(clearError()); }} message={error ? error : ""} />
      <Spin spinning={loading} size="large">
        <Form size="large">
          <div style={{ width: "100%", height: "100%" }}>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message:
                    getTranslation("loginMessageAlert-text-title2", dictionary),
                },
                {
                  required: true,
                  message:
                    getTranslation("loginMessageAlert-text-title1", dictionary),
                },
              ]}
            >
              <Input
                placeholder={
                  getTranslation("login-input-placeholder-email", dictionary)
                }
                onChange={(e) => setEmailInput(e.target.value)}
                size="large"
                autoComplete="on"
                style={{
                  fontFamily: "Mulish",
                  borderRadius: 10,
                  width: "100%",
                  backgroundColor: 'white'
                }}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message:
                    getTranslation("loginMessageAlert-text-title3", dictionary),
                },
              ]}
            >
              <Input.Password
                autoComplete="of"
                placeholder={
                  getTranslation("login-input-placeholder-password", dictionary)
                }
                onChange={(e) => setPasswordInput(e.target.value)}
                size="large"
                style={{ fontFamily: "Mulish", borderRadius: 10 }}
              />
            </Form.Item>
            <div style={{ marginBottom: '10px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={API_RECAPTCHA_SITE_KEY}
                onChange={onChange}
                style={{ marginBottom: 20 }}
              />

            </div>

          </div>
        </Form>
      </Spin>

      <Button
        onClick={handleOnStartLogInApp}
        type="primary"
        disabled={!captchaDone}
        htmlType="submit"
        style={{
          width: "100%",
          borderRadius: 20,
          background: captchaDone ? "linear-gradient(16deg, rgb(96, 82, 163), rgb(50, 45, 90))" : "linear-gradient(16deg, rgb(140, 125, 200), rgb(100, 90, 150))",
          color: captchaDone ? "white" : '#00000090',
        }}
      >
        {getTranslation("login-text-title", dictionary)}

      </Button>
    </div>
  );
};

// Exports
export default LoginForm;
