import { Button, Dropdown, Menu } from 'antd'
import React, { Dispatch } from 'react'
import { GlobalOutlined } from '@ant-design/icons'
import { StateGeneralRedux } from '../types/redux';
import { useDispatch, useSelector } from 'react-redux';
import { getDictionary, getLanguage } from '../store/ui';
import MensajeIngles from "../lang/diccionary-en.json";
import MensajeFrance from "../lang/diccionary-fr.json";

const LanguajeButton: React.FC = () => {

    // Stores
    const { language } = useSelector((state: StateGeneralRedux) => state.ui);
    const dispatch: Dispatch<any> = useDispatch();
    // Handlers
    const handleOnChangeLanguage = ({ key }: { key: string }) => {
        if (key === "fr" && language === "English") {
            dispatch(getLanguage("Français"));
            localStorage.setItem("lang", "Français");
            dispatch(getDictionary(MensajeFrance));
        } else if (key === "en" && language === "Français") {
            dispatch(getLanguage("English"));
            localStorage.setItem("lang", "English");
            dispatch(getDictionary(MensajeIngles));
        }
    };

    const languageMenu = (
        <Menu onClick={handleOnChangeLanguage}>
            <Menu.Item style={{ background: language === 'English' ? 'linear-gradient(16deg, rgb(96, 82, 163), rgb(50, 45, 90))' : '', color: language === 'English' ? 'white' : 'black' }} key="en">English</Menu.Item>
            <Menu.Item style={{ background: language === 'Français' ? 'linear-gradient(16deg, rgb(96, 82, 163), rgb(50, 45, 90))' : '', color: language === 'Français' ? 'white' : 'black' }} key="fr">Français</Menu.Item>
        </Menu>
    );
    return (
        <Dropdown overlay={languageMenu} placement="bottomRight">
            <Button
                icon={<GlobalOutlined />}

                style={{ background: 'linear-gradient(16deg, rgb(96, 82, 163), rgb(50, 45, 90))', color: 'white', borderRadius: '20px' }}
            >
                {language === "English" ? "Languaje" : "langage"}
            </Button>

        </Dropdown>
    )
}

export default LanguajeButton
