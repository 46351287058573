export enum EEmail {
    candidateLikeJob = 'candidateLikeJob',
    candidateDecline = 'candidateDecline',
    candidateReviewReminder = 'candidateReviewReminder',
    careerSwipeHiringManagerEmail = 'careerSwipeHiringManagerEmail',
    newCard = 'newCard',
    newMessage = 'newMessage',
    newCandidate = 'newCandidate',
    likeHiring = 'likeHiring'
}

export enum EEmailLanguage {
    English = 'English',
    French = 'French'
}