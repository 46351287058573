
// Dependencies
import { Col, Row, Typography } from 'antd'
import React from 'react'
import { assets } from '../../assets/assets'
import './AuthPages.css'
import LoginForm from '../../components/auth/LoginForm'
import { useSelector } from 'react-redux'
import { StateGeneralRedux } from '../../types/redux'
import { useNavigate } from 'react-router-dom'
import LanguajeButton from '../../common/LanguajeButton'
import { getTranslation } from '../../hooks/hooks'

const { Text } = Typography;
// Component
const AuthPages: React.FC = () => {

    // Store
    const { language, dictionary } = useSelector((state: StateGeneralRedux) => state.ui);
    const navigate = useNavigate();
    return (
        <Row className="row-content-general-auth">
            <Col className='left-section' xs={24} sm={24} md={24} lg={24} xl={11} xxl={14}>
                <div className="logo-image">
                    <img src={assets.irgtIcon} alt="" />
                </div>

                <h2 className='title'>{dictionary['register-page-welcome-message']}</h2>
                <p className='description'> {dictionary['register-page-secondary-message']}</p>
            </Col>
            <Col className='right-section' xs={20} sm={{ span: 18 }} md={18} lg={10} xl={13} xxl={10}>
                <div />
                <div>
                    <p className="title">Jobmanager - Dev</p>

                    <div className='container-buttons'>
                        <LoginForm />

                        <p style={{ fontSize: '16px', fontFamily: 'Mulish' }}>{getTranslation("change-password-informative-email-text", dictionary)}</p>
                        <LanguajeButton />

                        <div>
                            <Text
                                style={{ fontSize: '16px', cursor: "pointer" }}
                            >
                                {language === "English" ? "Don't have a Career Swipe account?" : "Vous n’avez pas de compte Career Swipe ? "}
                            </Text>
                            <Text style={{ fontSize: '16px', cursor: "pointer", color: '#5A5AD1', textDecoration: 'underline' }} onClick={() => navigate('/register')}> {language === "English" ? "Sign up now" : "S’inscrire maintenant"}</Text>
                        </div>
                    </div>
                </div>

                <div style={{ width: '100%', justifyContent: 'center' }}>
                    <p style={{ alignSelf: 'center', display: 'flex', fontSize: '16px', fontFamily: 'Mulish' }}>
                        {getTranslation("login-initial-page-info-page-text", dictionary)}{" "}
                        <a
                            href="https://gocareerswipe.com/"
                            style={{ marginLeft: '2px', fontSize: '16px' }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            gocareerswipe.com
                        </a>
                    </p>
                </div>
            </Col>
        </Row>
    )
}

export default AuthPages
