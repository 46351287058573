//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { Candidate } from "../../types/candidate";
import { Row, Col, Button } from "antd";
import User from "../../types/user";
import iconBtnAccept from "../../image/sourcing/btn-accept.png";
import iconBtnDecline from "../../image/sourcing/btn-decline.png";
import iconBtnAcceptFr from "../../image/btn-accept-fr.png";
import iconBtnDeclineFr from "../../image/btn-decline-fr.png";
import iconRight from "../../image/ico-next.png";
import iconLeft from "../../image/ico-prev.png";
import { Job } from "../../types/position";
import { Content } from "antd/lib/layout/layout";
import NotificationInteractionSourcing from "../modals/notificationModal/NotificationInteractionSourcing";
import { useSelector } from "react-redux";
import { uid } from "uid";
import { getTranslation } from "../../hooks/hooks";
import SkillSectionCandidateCard from "./SkillSectionCandidateCard";
import ExperienceSectionCandidateCard from "./ExperienceSectionCandidateCard";
import EducationSectionCandidateCard from "./EducationSectionCandidateCard";
import { db } from "../../firebase/config";
import {
  Timestamp,
  doc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { fetchSendEmail } from "../../services/api.services";
import { EEmail, EEmailLanguage } from "../../models/emails";

// Interface
interface Props {
  candidate: Candidate;
  user: User;
  position: any;
  home: boolean;
  prev?: () => void;
  next?: () => void;
}

// Component
const CandidateCard: React.FC<Props> = ({
  candidate,
  user,
  position,
  home,
  prev,
  next,
}) => {

  // States
  const token = useSelector((state: any) => state.auth.token);
  const { dictionary, language } = useSelector((state: any) => state.ui);

  // States
  const [experience, setExperience] = useState<Array<any>>([]);
  const [education, setEducation] = useState<Array<any>>([]);
  const [skill, setSkill] = useState<Array<any>>([]);
  const [loadingOnClickButton, setLoadingOnClickButton] = useState<boolean>(false);
  const [visibleModalNotification, setVisibleModalNotification] = useState<boolean>(false);
  const [type, setType] = useState<"like" | "dislike">("like");

  // Handlers
  const handleOnDislikeCandidate = async (position: Job) => {
    try {
      setLoadingOnClickButton(true);

      const candidateDocRef = doc(db, "hirings", user.idHiring, "positions", position.idPosition, "recommendationCandidates", candidate?.id);
      await updateDoc(candidateDocRef, { like: false, status: "" });


      if (candidate.promote === "candidate") {
        let idNotification = uid(30);
        let idCard = uid(24);

        // Enviar una tarjeta
        const notificationCardRef = doc(db, "notificationCards", candidate.id);
        await updateDoc(notificationCardRef, { idCandidate: candidate.id });

        const docRef = doc(db, "notificationCards", candidate.id, "cards", idCard);

        // Set the document with the provided data
        await setDoc(docRef, {
          title:
            position && position.language && position.language === "English"
              ? `${getTranslation(
                "joblist-decline-candidate-text-title",
                dictionary
              )} ${position.title}`
              : `${getTranslation(
                "joblist-decline-candidate-text-title",
                dictionary
              )}  ${position.title}`,
          description:
            position && position.language && position.language === "English"
              ? `${getTranslation("joblist-decline-candidate-text-description1", dictionary
              )} ${candidate.name} ${candidate.lastName} ,${getTranslation(
                "joblist-decline-candidate-text-description2",
                dictionary
              )} ${position.title} ${getTranslation(
                "joblist-decline-candidate-text-description3",
                dictionary
              )} `
              : `${getTranslation(
                "joblist-decline-candidate-text-description1",
                dictionary
              )} ${candidate.name} ${candidate.lastName},${getTranslation(
                "joblist-decline-candidate-text-description2",
                dictionary
              )} ${position.title} ${getTranslation(
                "joblist-decline-candidate-text-description3",
                dictionary
              )} `,
          idPosition: position.idPosition,
          idNotification: idNotification,
          idCard: idCard,
          statusView: false,
          dateCreatedNotification: Timestamp.now(),
          dateViewNotification: null,
          cardType: "declinedCandidate",
        });
      };

      setType("dislike");
      setVisibleModalNotification(true);
      setLoadingOnClickButton(false);
    } catch (error) {
      console.log("ERROR: handleOnDislikeCandidate", error);
    }
  };

  const handleOnLikeCandidate = async (position: Job) => {
    try {
      setLoadingOnClickButton(true);
      const positionCopy = {
        idHiring: user?.idHiring,
        idPosition: position.idPosition,
        checkBonnus: position.checkBonnus ? position.checkBonnus : false,
        checkMax: position.checkBonnus ? position.checkMax : false,
        checkMiddle: position.checkBonnus ? position.checkMiddle : false,
        checkMiMin: position.checkBonnus ? position.checkMin : false,
        title: position?.title ? position?.title : "Missing title",
        location: position?.location ? position?.location : "Missing location",
        description: position?.description
          ? position?.description
          : "Missing description",
        name: position?.name ? position?.name : "Missing name",
        lastName: position?.lastName ? position?.lastName : "Missing lastName",
        email: position?.email ? position?.email : "Missing email",
        company: user?.company ? user?.company : "Missing info company",
        salary: position.salary ? position?.salary : "Missing salary",
        companyInformation: user?.companyInformation
          ? user?.companyInformation
          : "Missing company information",
        responsibilities: position?.responsibilities
          ? position.responsibilities
          : [],
        technicalRequirements: position?.technicalRequirements
          ? position?.technicalRequirements
          : [],
        skills: position?.skills ? position?.skills : [],
        status: position?.status ? position?.status : "open",
        workplace: position?.workplace ? position?.workplace : [],
        like: null,
        hourForWeek:
          position.hourForWeek === null || position.hourForWeek === undefined
            ? 40
            : position.hourForWeek,
        otherDetailsCompensation:
          position.otherDetailsCompensation === "" ||
            position.otherDetailsCompensation === null ||
            position.otherDetailsCompensation === undefined
            ? ""
            : position.otherDetailsCompensation,
        timestampLike: null,
        createAt: position?.createAt ? position?.createAt : null,
        jobType: position?.jobType ? position?.jobType : [],
        type: "job",
        statusMatch: "pending",
        amount: position?.amount ? position?.amount : false,
        annual: position?.annual ? position?.annual : false,
        percentage: position?.percentage ? position?.percentage : false,
        hourly: position?.hourly ? position.hourly : false,
        totalSalary: position?.totalSalary ? position?.totalSalary : "",
      };

      setVisibleModalNotification(true);
      setType("like");
      const docRef = doc(db, "candidates", candidate?.id, "recommendation", position?.idPosition);

      // Set the document with the provided data
      await setDoc(docRef, positionCopy);

      const recommendationCandidatesRef = doc(db, "hirings", user.idHiring, "positions", position.idPosition, "recommendationCandidates", candidate.id);

      await Promise.all([
        updateDoc(recommendationCandidatesRef, {
          like: true,
          status: "waiting for reply",
          candidateLike: "",
        }),
        await fetchSendEmail(candidate.email, EEmail.likeHiring, position.language === "English" ? EEmailLanguage.English : EEmailLanguage.French, {
          fullName: candidate.name + ' ' + candidate.lastName,
          companyName: position.company,
        }, token),
      ])

      setLoadingOnClickButton(false);

    } catch (error) {
      console.log("HandleOnLikeCandidate Function: ", error);
      setLoadingOnClickButton(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const candidateRef = doc(db, "candidates", candidate.id);
      const unsubscribe = onSnapshot(candidateRef, (candidateSnap) => {
        const candidateData = candidateSnap.data();

        if (candidateData) {
          candidateData.skills && setSkill(candidateData.skills);

          const experiences: Array<any> = [];
          const education: Array<any> = [];

          candidateData.experience?.forEach((experienceData: any) => {
            const startFullYear = new Timestamp(
              experienceData.dateStart.seconds,
              experienceData.dateStart.nanoseconds
            )
              .toDate()
              .getFullYear();

            if (experienceData.actualPosition) {
              experiences.push({
                ...experienceData,
                dateStart: startFullYear,
                dateEnd: Timestamp.now().toDate().getFullYear(),
                dateEndComplete: Timestamp.now().seconds,
              });
            } else {
              const endFullYear = new Timestamp(
                experienceData.dateEnd.seconds,
                experienceData.dateEnd.nanoseconds
              )
                .toDate()
                .getFullYear();

              experiences.push({
                ...experienceData,
                dateStart: startFullYear,
                dateEnd: endFullYear,
                dateEndComplete: experienceData.dateEnd.seconds,
              });
            }
          });

          candidateData.education?.forEach((educationData: any) => {
            if (educationData.graduationDate) {
              const graduationDate = new Timestamp(educationData.graduationDate.seconds, educationData.graduationDate.nanoseconds).toDate().getFullYear();

              education.push({
                ...educationData,
                dateGraduationDateOk: graduationDate,
              });
            } else {
              education.push({
                ...educationData,
                dateGraduationDateOk: new Date(),
              });
            }
          });

          setExperience(
            experiences.sort((a, b) => b.dateEndComplete - a.dateEndComplete)
          );
          setEducation(
            education.sort(
              (a, b) => b.dateGraduationDateOk - a.dateGraduationDateOk
            )
          );
        }
      });

      return () => unsubscribe();
    }, 500);
  }, [candidate.id]);

  return (
    <Content
      style={{
        width: "98%",
        height: "90vh",
        borderRadius: 10,
        border: "1px solid",
        boxShadow: "7px 7px 15px #00000016",
        borderColor: "#00000020",
        marginLeft: "1%",
        marginRight: "1%",
        marginBottom: "0.5%",
        marginTop: "0.5%",
      }}
    >
      <Row style={{ height: "78%", width: "100%" }}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={{ span: 12 }}
          xxl={13}
          style={{ height: "90%", width: "100%" }}
        >
          <ExperienceSectionCandidateCard
            candidate={candidate}
            experience={experience}
          />
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={{ span: 12 }}
          xxl={11}
          style={{ height: "90%", width: "100%", borderColor: "#00000020" }}
        >
          <EducationSectionCandidateCard
            education={education}
          />
        </Col>
      </Row>

      <Row style={{ height: "12%" }}>
        <SkillSectionCandidateCard skill={skill} />
      </Row>

      {home && (
        <Row
          style={{
            width: "100%",
            paddingBottom: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10%",
          }}
        >
          <Col
            xs={24}
            sm={12}
            md={6}
            lg={6}
            xxl={6}
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              paddingLeft: 50,
            }}
          >
            <Button
              disabled={loadingOnClickButton}
              onClick={prev}
              style={{
                borderColor: "transparent",
                height: 40,
                width: 40,
                opacity: loadingOnClickButton ? 0.4 : 1,
              }}
              icon={
                <img
                  src={iconLeft}
                  style={{ height: 40, width: 40, opacity: 0.5 }}
                  alt={iconBtnAccept}
                />
              }
            />
          </Col>

          <Col
            xs={24}
            sm={12}
            md={6}
            lg={6}
            xxl={6}
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              disabled={loadingOnClickButton}
              onClick={() => handleOnLikeCandidate(position)}
              style={{
                borderColor: "transparent",
                height: 50,
                width: 150,
                opacity: loadingOnClickButton ? 0.4 : 1,
              }}
              icon={
                <img
                  src={language === "English" ? iconBtnAccept : iconBtnAcceptFr}
                  style={{ height: 80, width: 180, marginTop: -10 }}
                  alt={iconBtnAccept}
                />
              }
            />
          </Col>

          <Col
            xs={24}
            sm={12}
            md={6}
            lg={6}
            xxl={6}
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              disabled={loadingOnClickButton}
              onClick={() => handleOnDislikeCandidate(position)}
              style={{
                borderColor: "transparent",
                height: 50,
                width: 150,
                opacity: loadingOnClickButton ? 0.4 : 1,
              }}
              icon={
                <img
                  src={
                    language === "English" ? iconBtnDecline : iconBtnDeclineFr
                  }
                  style={{ height: 80, width: 180, marginTop: -10 }}
                  alt={iconBtnAccept}
                />
              }
            />
          </Col>

          <Col
            xs={24}
            sm={12}
            md={6}
            lg={6}
            xxl={6}
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: 50,
            }}
          >
            <Button
              disabled={loadingOnClickButton}
              onClick={next}
              style={{
                borderColor: "transparent",
                height: 40,
                width: 40,
                opacity: loadingOnClickButton ? 0.4 : 1,
              }}
              icon={
                <img
                  src={iconRight}
                  style={{ height: 40, width: 40, opacity: 0.7 }}
                  alt={iconBtnAccept}
                />
              }
            />
          </Col>
        </Row>
      )}

      <NotificationInteractionSourcing
        visible={visibleModalNotification}
        closeModal={() => {
          setVisibleModalNotification(false);
          setType("like");
        }}
        type={type}
      />
    </Content>
  );
};

export default CandidateCard;
