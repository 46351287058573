//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Menu } from "antd";
import { MessageOutlined } from '@ant-design/icons';
import { db } from "../firebase/config";

import { logout } from "../store/auth";

import iconUser from "../image/iconProfile.png";
import iconMartillo from "../image/iconMartillo.png";
import iconLogout from "../image/iconLogoutWhite.png";
import iconLangEn from "../image/icon-web-en.png";
import iconLangFr from "../image/icon-web-fr.png";
import iconIRGTLogoWhite from "../image/navbar/logo-CS-horizontal_white-TM.png";
import { logoutFirebase } from "../firebase/providers";
import { getDictionary, getLanguage } from "../store/ui";
import MensajeIngles from "../lang/diccionary-en.json";
import MensajeFrance from "../lang/diccionary-fr.json";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  runTransaction,
  updateDoc,
  where,
} from "firebase/firestore";
import { clearUserSelected } from "../store/job";
import { clearFilterJob, clearMessageList, clearPositionSelect, clearUserChatSelected, getFilterJob, startGetMessageInboxList, startGetMessageUnreadCounter, startGetPositionListChat } from "../store/chat";

//Components
const NavBar: React.FC = () => {
  //States
  const navigate = useNavigate();
  const { language } = useSelector((state: any) => state.ui);
  const user = useSelector((state: any) => state.auth.user);

  const dispatch: any = useDispatch();

  //Handlers
  const LogOut = useCallback(async () => {
    logoutFirebase();
    dispatch(logout());
    navigate("/login");
  }, [navigate, dispatch]);

  const handleOnChangeLanguage = async () => {
    if (language === "English") {
      localStorage.setItem("lang", "Français");
      const candidateDocRef = doc(db, "hirings", user?.idHiring);
      await updateDoc(candidateDocRef, {
        language: "Français",
      });
      dispatch(getLanguage("Français"));
      dispatch(getDictionary(MensajeFrance));
    } else {
      localStorage.setItem("lang", "English");
      const candidateDocRef = doc(db, "hirings", user?.idHiring);
      await updateDoc(candidateDocRef, {
        language: "English",
      });
      dispatch(getLanguage("English"));
      dispatch(getDictionary(MensajeIngles));
    }
  };

  const handleOnNavigateSupportScreen = async (
    idHiring: string,
    name: string,
    lastName: string,
    company: string
  ) => {
    try {
      const candidateDocRef = doc(
        db,
        "admin",
        "huiCo2D8l6ZY7NuyNruK6NH6CCG3",
        "messages",
        idHiring
      );
      const candidateDoc = await getDoc(candidateDocRef);

      if (!candidateDoc.exists()) {
        await runTransaction(db, async (transaction) => {
          const fromMessagesRef = doc(
            db,
            "hirings",
            idHiring,
            "messages",
            "huiCo2D8l6ZY7NuyNruK6NH6CCG3"
          );
          const toMessagesRef = doc(
            db,
            "admin",
            "huiCo2D8l6ZY7NuyNruK6NH6CCG3",
            "messages",
            idHiring
          );

          const fromMessageData = {
            id: "huiCo2D8l6ZY7NuyNruK6NH6CCG3",
            name: "Administrator",
            lastName: "Support",
            dateLastMessage: Timestamp.now(),
            messagesPending: false,
            notReaded: 0,
          };

          const toMessageData = {
            idUser: idHiring,
            name: name,
            lastName: lastName,
            company: company,
            dateLastMessage: Timestamp.now(),
            messagesPending: false,
            notReaded: 0,
            typeUser: "hiring",
          };

          // Establece los documentos en las subcolecciones correspondientes
          transaction.set(fromMessagesRef, fromMessageData);
          transaction.set(toMessagesRef, toMessageData);
        });
      }

      navigate("/support");
    } catch (error) {
      console.error(error);
    }
  };

  const [messagesPending, setMessagesPending] = useState<boolean>(false);
  const userChatSelected = useSelector((state: any) => state.chat.userChatSelected);

  useEffect(() => {
    const messagesPending = [];

    const messagesCollectionRef = collection(db, "hirings", user?.idHiring, "messages");
    const messagesQuery = query(messagesCollectionRef, where("messagesPending", "==", true))

    const unsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
      querySnapshot.forEach((messageDoc) => {
        const messageData = messageDoc.data();
        if (messageData.id !== 'huiCo2D8l6ZY7NuyNruK6NH6CCG3') {
          messagesPending.push(messageData);
        }
      });

      if (messagesPending.length > 0) {
        setMessagesPending(true);
      } else {
        setMessagesPending(false);
      }
    });

    return () => unsubscribe();
  }, [userChatSelected, user?.idHiring]);
  useEffect(() => {
    const messagesCollectionRef = collection(db, "hirings", user?.idHiring, "messages");
    const messagesQuery = query(messagesCollectionRef, where("messagesPending", "==", true))
    const unsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
      querySnapshot.forEach((messages) => {
        if (messages.data()) {
          setMessagesPending(true);
        } else {
          setMessagesPending(false);
        }
      });


    });

    return () => unsubscribe();
  });

  const { token } = useSelector((state: any) => state.auth);

  const startResetDataJobList = () => {
    dispatch(clearUserSelected());
  }
  const location = useLocation();
  const goToMessageScreen = async () => {
    location.pathname === "/jobList" && startResetDataJobList();

    if (location.pathname === "/messages") {
      //Clear chat selected
      dispatch(clearUserChatSelected());
      //Clear filters
      dispatch(clearFilterJob());
      // Clear position message selected
      dispatch(clearPositionSelect());
      dispatch(clearMessageList());
    } else {
      dispatch(startGetMessageUnreadCounter(user));

      dispatch(startGetMessageInboxList(user, token));
      dispatch(startGetPositionListChat(user));


      dispatch(getFilterJob("Inbox"));

      navigate("/messages");
    }
  };

  return (
    <Menu
      style={{ backgroundColor: "#6052A3", height: "100%", width: "100%" }}
      mode="horizontal"
    >

      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ height: '100%', flexDirection: 'row', display: 'flex', alignItems: 'center', boxSizing: 'border-box', paddingLeft: '20px' }}>
          <img
            alt="icon Irgt"
            src={iconIRGTLogoWhite}
            style={{ height: "70%", width: "auto", cursor: "pointer" }}
            onClick={() => navigate("/dash")}
          />

        </div>

        <div style={{ height: '100%', display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', boxSizing: 'border-box', paddingRight: '20px' }}>
          <Button
            onClick={handleOnChangeLanguage}
            style={{
              borderColor: "transparent",
              backgroundColor: "transparent",
            }}
          >
            {language === "English" ? (
              <img
                src={iconLangFr}
                style={{ height: 35, width: "auto", marginTop: -5 }}
                alt={iconLangFr}
              />
            ) : (
              <img
                src={iconLangEn}
                style={{ height: 35, width: "auto", marginTop: -5 }}
                alt={iconLangEn}
              />
            )}
          </Button>

          <Button
            onClick={() =>
              handleOnNavigateSupportScreen(
                user?.idHiring,
                user?.name,
                user?.lastName,
                user?.company
              )
            }
            style={{
              borderColor: "transparent",
              backgroundColor: "transparent",
            }}
            icon={
              <img
                src={iconMartillo}
                style={{ height: 25, width: "auto" }}
                alt={iconMartillo}
              />
            }
          />

          <Button
            onClick={() => navigate("/profile")}
            style={{
              borderColor: "transparent",
              backgroundColor: "transparent",
            }}
            icon={
              <img
                src={iconUser}
                style={{ height: 25, width: "auto" }}
                alt={iconUser}
              />
            }
          />
          <div style={{ position: 'relative', display: 'flex', height: '100%', flexDirection: 'row', alignItems: 'center' }}>
            <Button
              onClick={goToMessageScreen}
              style={{
                borderColor: "transparent",
                backgroundColor: "transparent",
              }}
              icon={
                <MessageOutlined style={{ fontSize: 25, color: "white" }} />
              }
            />
            {
              messagesPending && (
                <div style={{ height: 15, width: 15, backgroundColor: '#D12525', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 10, position: "absolute", top: 17, right: -3 }}></div>
              )
            }

          </div>

          <Button
            onClick={LogOut}
            style={{
              borderColor: "transparent",
              backgroundColor: "transparent",
            }}
            icon={
              <img
                src={iconLogout}
                style={{ height: 26, width: "auto" }}
                alt={iconLogout}
              />
            }
          />
        </div>
      </div>
    </Menu>
  );
};

//Export
export default NavBar;
