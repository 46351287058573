export enum CollectionsName {
    CANDIDATES = 'candidates',
    ADMIN = 'admin',
    APLICATIONMOBILE = 'aplicacionMobile',
    AUTOFILL = 'autofill',
    COMPANYQR = 'companyQR',
    FAILEDLOGINATTEMPTS = 'failedLoginAttemps',
    HIRINGS = 'hirings',
    HISTORYSCORE = 'historyScore',
    JOBVISITEDBY = 'jobVisitedBy',
    MAIL = 'mail',
    METADATA = 'metadata',
    NOTIFICATIONCCARDS = 'notificationCards',
    CARDS = 'cards',
    ONBOARDING = 'onboarding',
    PRECANDIDATES = 'preCandidates',
    SNEAKPEEK = 'sneakPeek',
    SNEAKPEEKCANDIDATE = 'sneakSpeekCandidate',
    TEMPLATES = 'template',
    POSITIONS = 'positions',
    JOBPREFERENCES = 'jobPreferences',
    METADATA_TITLES = 'metadata-title',
    RECOMMENDATIONCANDIDATES = 'recommendationCandidates',
    RECOMMENDATIONPRECANDIDATES = 'recommendationPreCandidates'

}